import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Copyright from '../Copyright/Copyright';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));


export default function Done() {
  const classes = useStyles();

  return (
          <main className={classes.layout}>
            <Paper className={classes.paper} elevation={0}>
                <Typography component="h1" variant="h4" align="center">
                  Thank you.
                </Typography>
                <Typography variant="subtitle1">
                We have submitted your registration to Shell for review.
              </Typography>
              <Typography variant="subtitle1">
                The review may take up to 7 days.
              </Typography>
              <Typography variant="subtitle1">
                Once finished we will contact you about the next steps.
              </Typography>
            </Paper>
            <Copyright />
          </main>
  );
}