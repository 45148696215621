import { blue } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: blue,
  //     contrastText: '#ffffff'
  //   },
  //   secondary: {
  //     main: '#19857b',
  //   },
  //   error: {
  //     main: red.A400,
  //   },
  //   background: {
  //     default: '#fff',
  //   },
  // },
  components: {
    MuiListItemButton: {
      // follow https://material-ui.com/r/pseudo-classes-guide
      styleOverrides: {
        // Name of the slot
        "root": {
          "&.Mui-selected": {
            "backgroundColor": blue[200]
          },
          "&.Mui-selected:hover": {
            "backgroundColor": blue[200]
          }
        }
      },
    },
  },
});

export default theme;