import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AppStateContext from '../../AppStateContext';
import React from 'react';

export const NotFoundTable = ({showDelete}) => {

    return(
        <AppStateContext.Consumer>
            {(appState) => (
                <React.Fragment>
                    <Typography variant="h6" sx={{marginTop: '10px'}}>Stations not on Shell Locator</Typography>
                    
                    <TableContainer>
                    <Table aria-label="list of stations">
                        <TableHead>
                            <TableRow>
                                <TableCell>Address</TableCell>
                                {showDelete && <TableCell></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {appState.notFound.map((station, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">{station}</TableCell>
                                    {showDelete &&
                                        <TableCell align="left">
                                            <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => appState.deleteNotFound(station)}
                                            >
                                            Delete
                                            </Button>
                                        </TableCell>
                                    } 
                                
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <Typography variant="caption">These are stations not on the shell website and will have their details verified later</Typography>
                </React.Fragment>
            )}
        </AppStateContext.Consumer>
    );
}

export default NotFoundTable;