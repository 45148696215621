export const formatAddress = (station) => {
    const address = [];
    if(station.address1) {
      address.push(station.address1);
    }
    if(station.address2) {
      address.push(station.address2);
    }
    if(station.town) {
      address.push(station.town);
    }
    if(station.city) {
      address.push(station.city);
    }
    if(station.postcode) {
      address.push(station.postcode);
    }
    return (address.join(', '));
  }

export default formatAddress;