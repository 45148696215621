import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Home from './components/Home/Home';
import Process from './components/Process/Process';
import Done from './components/Process/Done';
import MainAppBar from './components/MainAppBar/MainAppBar';
import { AppStateProvider } from './AppStateContext';

export default function App() {
  return (
    <AppStateProvider>
      <Router>
        <MainAppBar />
        <Switch>
          <Route path="/register">
            <Container maxWidth="sm">
              <Box sx={{ my: 4 }}>
                <Process />
              </Box>
            </Container>
          </Route>

          <Route path="/done">
            <Container maxWidth="sm">
              <Box sx={{ my: 4 }}>
                <Done />
              </Box>
            </Container>
          </Route>
          
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        
      </Router>
  </AppStateProvider>
  );
}