import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AppStateContext from '../../AppStateContext';
import formatAddress from '../../utils/formatAddress';

export const StationsTable = ({showDelete}) => {

    return(
        <AppStateContext.Consumer>
            {(appState) => (
                <>
                    <Typography sx={{marginTop: '10px'}} variant="h6">Stations added</Typography>
                    
                    <TableContainer>
                    <Table aria-label="list of stations">
                        <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                            {showDelete && <TableCell></TableCell>}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {appState.stations.map((station, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">{station.name}</TableCell>
                                    <TableCell align="left">{formatAddress(station)}</TableCell>
                                    {showDelete &&
                                        <TableCell align="left">
                                            <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => appState.deleteStation(station)}
                                            >
                                            Delete
                                            </Button>
                                        </TableCell>
                                    } 
                                
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <Typography variant="caption">These stations were found on the shell website</Typography>
                </>
            )}
        </AppStateContext.Consumer>
    );
}

export default StationsTable;