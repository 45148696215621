import React, {useState, useContext} from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

import { parseDataFromFile } from '../../services/ParseStationData';
import { findStationsNearPostCode } from '../../services/Stations';

import AppStateContext from '../../AppStateContext';

import { StyledDropzone } from '../DropZone/StyledDropzone';
import { Box } from '@material-ui/core';
import fuzzyAddressMatch from '../../utils/fuzzyAddressMatch';

export const BulkUpload = () => {
    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const [invalidCSV, setInvalidCSV] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [snackMessage, setSnackMessage] = useState('');
    const [parsingFile, setParsingFile] = useState(false);

    const [open, setOpen] = useState(false);

    const appContext = useContext(AppStateContext);

    const onDropUserData = async (file) => {
        // console.log(file[0]);
        if(file[0].type !== 'text/csv') {
            setInvalidCSV(true);
            return;
        } else {
            setInvalidCSV(false);
        }

        setParsingFile(true);

        let data = await parseDataFromFile(file[0]);
        // console.log(data);
        
        if (data.error) {
            setErrorMessage(data.error);
            return;
        } else {
            setErrorMessage('');
            data = data.stationData;
        }

        const foundStations = [];
        const notFoundStations = [];

        for(let i=0; i < data.length; i++) {
            const stations = await findStationsNearPostCode(data[i].postcode);
            // console.log(stations);
            const result = matchStation(data[i], stations);
            // console.log(result);
            if(result.match) {
                foundStations.push(result.match);
            } else {
                notFoundStations.push(`${data[i].street}, ${data[i].town}, ${data[i].city}, ${data[i].postcode}`);
            }
        }

        // console.log(foundStations);
        appContext.addStation(foundStations);
        appContext.addNotFound(notFoundStations);
        
        setParsingFile(false);

        setSnackMessage(`Added ${foundStations.length + notFoundStations.length} Stations`);
        // if(notFoundStations.length === 0) { // all the stations have been added
            setOpen(false);
        // }
    }

    const matchStation = (input, stations) => {
        let startNumber = input.street.match(/^\d*/);
        if(startNumber.length > 0) {
            startNumber = startNumber[0];
        } else {
            startNumber = false;
        }
        // console.log(startNumber);

        for(let i = 0; i < stations.length; i++) {
            
            let fullPostcodeMatch = (input.postcode === stations[i].postcode);
            // console.log(input.postcode, stations[i].postcode)
            // console.log(fullPostcodeMatch);

            let startNumberMatch = false;
            if(startNumber) {
                startNumberMatch = stations[i].address1.startsWith(startNumber);
            }

            if(fullPostcodeMatch && startNumberMatch) {
                return({
                    match: stations[i]
                });
            }

            // See if it matches for 5 digit post codes and street address
            const stations5DigitPostCode = stations[i].postcode.match(/^\d*/);
            const input5DigitPostCode = input.postcode.match(/^\d*/);
            if(stations5DigitPostCode.length > 0 && input5DigitPostCode.length > 0 && stations5DigitPostCode[0] === input5DigitPostCode[0]) { // 5 digit zip codes match
                if (input.street.toUpperCase() === stations[i].address1.toUpperCase()) { // the streets match
                    return({
                        match: stations[i]
                    });
                }

                // perform fuzzy logic
                if(fuzzyAddressMatch(input.street, stations[i].address1, 3)) {
                    return({
                        match: stations[i]
                    });
                }
            }

        }

        return({
            input,
            stations
        })

    }

    const onClose = () => {
        if(!parsingFile) {
            setOpen(false);
        }
    }

    return(
        <React.Fragment>
            <Button onClick={()=>setOpen(true)}>Add Stations From File</Button>
            <Dialog onClose={() => onClose()} open={open} onBackdropClick={() => onClose()}>
                <div className="modal" style={{maxWidth: '100%'}}>
                <DialogTitle>Add multiple stations from a file</DialogTitle>
                
                    {mobileScreen ?
                        <Box sx={{margin:'0px 24px 24px 24px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Typography>If you would like to add many stations from a spreadsheet where you already have the data then please use a computer browser to do this.</Typography>
                            <Button
                                disabled={parsingFile}
                                variant="text"
                                color="primary"
                                onClick={()=>setOpen(false)}
                                sx={{marginTop: '16px'}}
                            >
                                Close
                            </Button>
                        </Box>
                    :

                        <Box sx={{margin:'0px 24px 24px 24px'}}>To upload stations from data you have on your computer.
                            <ol>
                                <li><a href="/fuelservice-bulk-stations.csv">Click here to download the template</a></li>
                                <li>Fill it in with your stations information in Excel. Please keep the same headers and order and save as a CSV file.</li>
                                <li>Drag the file into the box below, or click in the box and choose the file to upload it.</li>
                            </ol>
                        
                            {invalidCSV && <div>Please only upload a CSV file</div>}
                            {errorMessage && <div>{errorMessage}</div>}

                            <StyledDropzone 
                                processing={parsingFile}
                                processingMessage="Processing data"
                                onDrop={onDropUserData}
                                message="Drop your csv file here, or click to select" 
                            />

                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '16px'}}>
                                <Button
                                    disabled={parsingFile}
                                    variant="text"
                                    color="primary"
                                    onClick={()=>setOpen(false)}
                                >
                                    Cancel
                                </Button>
                            </Box>

                        </Box>
                    }
                
                </div>
            </Dialog>
            <Snackbar
                open={!!snackMessage}
                autoHideDuration={3000}
                onClose={() => setSnackMessage('')}
                message={snackMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </React.Fragment>
    )
}

export default BulkUpload;

