import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Copyright from '../Copyright/Copyright';

import AppStateContext from '../../AppStateContext';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppStateContext.Consumer>
      {(appState) => (
        <React.Fragment>
          <main className={classes.layout}>
            <Paper className={classes.paper} elevation={0}>
                <Typography component="h1" variant="h4" align="center" gutterBottom>
                  Helping you, help your customers with disabilities to fill up
                </Typography>
             
                <React.Fragment>
                    {/* <Typography variant="h5" align="center" gutterBottom>
                    Helping you help your customers with disabilities get help refueling
                    </Typography> */}
                    <Typography variant="subtitle1" gutterBottom>
                    Shell have partnered with fuelService to enhance the customer journey for customers with disabilities.
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                    In the past assistance was given in an adhoc manner of beeping horns, flashing lights and hoping someone would notice.
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                    With fuelService customers can ask if you are able to assist using their smartphone in a seamless way.
                    </Typography>
                    <Typography variant="subtitle1">
                    There is NO charge for enabling fuelService. Shell is covering all costs and materials for their dealers. 
                    </Typography>
                </React.Fragment>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Button
                            variant="contained"
                            color="primary"
                            align="center"
                            onClick={()=>history.push("/register")}
                            className={classes.button}
                            size="large"
                        >
                            Register Now
                    </Button>
                </div>
              
            </Paper>
            <Copyright />
          </main>
        </React.Fragment>
      )}
    </AppStateContext.Consumer>
  );
}