import React from 'react';
import Typography from '@material-ui/core/Typography';

import AppStateContext from '../../AppStateContext';
import StationsTable from './StationsTable';
import NotFoundTable from './NotFoundTable';

export default function Review() {

  return (
    <AppStateContext.Consumer>
      {(appState) => (
        <React.Fragment>
          <Typography variant="h6" gutterBottom>
            Please review and submit
          </Typography>

          <table>
            <tbody>
              <tr>
                <td><strong>Name</strong></td>
                <td style={{paddingLeft: '30px'}}>{appState.name}</td>
              </tr>
              <tr>
                <td><strong>Company</strong></td>
                <td style={{paddingLeft: '30px'}}>{appState.dealerName}</td>
              </tr>
              <tr>
                <td><strong>Email</strong></td>
                <td style={{paddingLeft: '30px'}}>{appState.email}</td>
              </tr>
              <tr>
                <td><strong>Phone</strong></td>
                <td style={{paddingLeft: '30px'}}>{appState.phone}</td>
              </tr>
            </tbody>
          </table>

          {appState.stations.length > 0 && 
            <StationsTable />
          }

          {appState.notFound.length > 0 && 
            <NotFoundTable />
          }
        
        </React.Fragment>
      )}
    </AppStateContext.Consumer>
  );
}