import parse from 'csv-parse';

export const parseDataFromFile = (file) => {

    return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.onload = () => {
            parse(reader.result, (err, data) => {

                if(err) {
                    reject(err);
                    return;
                }
            
                // Get the data from the files into an array of objects
                const stationData = convertToArray(data);

                resolve(stationData);
            
            });
        };

        reader.readAsText(file);
    });
}

function convertToArray(data) {

    var stationData = [];

    for (var i = 0; i < data.length; i++) {

        if(String(data[i][0]).toLowerCase().startsWith('street address')) {
            continue;
        }
        const newStation = {
            street: data[i][0],
            town: data[i][1],
            city: data[i][2],
            postcode: data[i][3],
        };

        if(newStation.street === '' && newStation.town === '' && newStation.city === '' && newStation.postcode === '') {
            continue;
        }

        if(newStation.street === '' || newStation.postcode === '') {
            return({
                error: 'Invalid Data. Please make sure you have entered a Street Address and Zip Code for every station in the file'
            });
        }

        stationData.push(newStation);

    };

    return({stationData});

}

