import React, {Component} from 'react';

import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { sendRegistration } from './services/Registration';

const AppStateContext = React.createContext();

export class AppStateProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /* REMEMBER TO CHANGE THE STEP TO FALSE */
      // name: 'Niall',
      // email: 'niallel@me.com',
      // dealerName: 'fuelService',
      // phone: '07720884650',
      name: '',
      email: '',
      dealerName: '',
      phone: '',

      stations: [],
      notFound: [],

      stepValid: [
        true, // Not used
        // true, // Your Details <- CHANGE TO FALSE
        false, // Your Details <- CHANGE TO FALSE
        false, // Add Stations
        true], // Review
        
    };
  }

    validateContact() {
      this.changeStepValidation(1, 
        !(
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(this.state.email).toLowerCase()) || 
          this.state.name === "" ||
          this.state.phone === "" ||
          this.state.dealerName === "" 
        )
      );
    }

    changeStepValidation = (step, valid) => {
      const stepValid = [...this.state.stepValid];
      stepValid[step] = valid;
      this.setState({stepValid});
    }

    addStation = (addedStation) => {
      console.log(addedStation);
      let stations = JSON.parse(JSON.stringify(this.state.stations))

      if(Array.isArray(addedStation)) {
        stations = stations.concat(addedStation);
      } else {
        stations.push(addedStation);
      }

      stations = uniqBy(stations, 'idstations'); // remove duplicates with the same idstations number

      this.setState({stations}, this.changeStepValidation(2, true));
    }

    deleteStation = (deletedStation) => {
      const stations = JSON.parse(JSON.stringify(this.state.stations));
      for(let i=0; i < stations.length; i++) {
        if(stations[i].idstations === deletedStation.idstations) {
          stations.splice(i, 1);
        }
      }
      this.setState({stations}, this.changeStepValidation(2, this.state.stations.length > 0 || this.state.notFound.length > 0) );
    }

    addNotFound = (addedLocation) => {

      let notFound = JSON.parse(JSON.stringify(this.state.notFound))

      if(Array.isArray(addedLocation)) {
        notFound = notFound.concat(addedLocation);
      } else {
        notFound.push(addedLocation);
      }

      notFound = uniq(notFound); // remove duplicates with the same address

      this.setState({notFound}, this.changeStepValidation(2, true));
    }

    deleteNotFound = (deletedLocation) => {
      const notFound = JSON.parse(JSON.stringify(this.state.notFound));
      for(let i=0; i < notFound.length; i++) {
        if(notFound[i] === deletedLocation) {
          notFound.splice(i, 1);
        }
      }
      this.setState({notFound}, this.changeStepValidation(2, this.state.stations.length > 0 || this.state.notFound.length > 0) );
    }

    submitRegistration = () => {
      return new Promise((resolve, reject) => {
        const stationIds = [];
        for(let i=0; i < this.state.stations.length; i++) {
          stationIds.push(this.state.stations[i].idstations);
        }
        // console.log(JSON.stringify(stationIds));
        const result = sendRegistration({
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          dealerName: this.state.dealerName,
          stations: stationIds,
          notFound: this.state.notFound
        });
        resolve(result);
      })
    }

  render() {
    return (
      <AppStateContext.Provider
        value={{

          // your details
          email: this.state.email,
          setEmail: (email) => this.setState({email}, () => this.validateContact()),
          name: this.state.name,
          setName: (name) => this.setState({name}, () => this.validateContact()),
          dealerName: this.state.dealerName,
          setDealerName: (dealerName) => this.setState({dealerName}, () => this.validateContact()),
          phone: this.state.phone,
          setPhone: (phone) => this.setState({phone}, () => this.validateContact()),

          // Stations
          stations: this.state.stations,
          addStation: (addedStation) => this.addStation(addedStation),
          deleteStation: (deletedStation) => this.deleteStation(deletedStation),

          // Not found
          notFound: this.state.notFound,
          addNotFound: (location) => this.addNotFound(location),
          deleteNotFound: (location) => this.deleteNotFound(location),

          submitRegistration: this.submitRegistration,

          stepValid: this.state.stepValid,
          
        }}>
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
}

export default AppStateContext;
