import React, { useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import AppStateContext from '../../AppStateContext';
import GooglePlacesAutocomplete from './GooglePlacesAutocomplete';
import SelectStation from './SelectStation';
import { findStations } from '../../services/Stations';
import StationsTable from './StationsTable';
import BulkUpload from './BulkUpload';
import NotFoundTable from './NotFoundTable';

export default function AddStations() {
  
  const [locationValue, setLocationValue] = useState(null);
  const [locationInputValue, setLocationInputValue] = useState('');
  const [searchStations, setSearchStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const appContext = useContext(AppStateContext);

  const onSetLocation = async (location) => {
    // console.log(location);
    setLoading(true);
    const result = await findStations(location.lat, location.lng, 10, 'shell', 5);
    setLoading(false);
    // console.log(result);
    setSearchStations(result);
  }

  const stationSelected = (station) => {
    if(station) {
      appContext.addStation(station);
      if(station) {
        setSnackMessage(`Added ${station.name}`);
      }
    }

    // reset the location value
    setLocationValue(null);
  }

  const stationNotFound = (location) => {
    appContext.addNotFound(location.description)

    // reset the location value
    setLocationValue(null);
  }

  return (
    <AppStateContext.Consumer>
      {(appState) => (
        <React.Fragment>
          <Typography variant="h6" gutterBottom>
            Add your stations
          </Typography>
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <GooglePlacesAutocomplete 
                onSetLocation={onSetLocation}
                value={locationValue}
                setValue={setLocationValue}
                inputValue={locationInputValue}
                setInputValue={setLocationInputValue}
              />
              <BulkUpload />
            </Grid>
          </Grid>

          {appState.stations.length > 0 && 
            <StationsTable showDelete/>
          }

          {appState.notFound.length > 0 && 
            <NotFoundTable showDelete/>
          }
          
          <SelectStation loading={loading} location={locationValue} stations={searchStations} stationSelected={stationSelected} stationNotFound={stationNotFound}/>

          <Snackbar
            open={!!snackMessage}
            autoHideDuration={3000}
            onClose={() => setSnackMessage('')}
            message={snackMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
        
        </React.Fragment>
      )}
      </AppStateContext.Consumer>
  )
}
