import React, {useState} from 'react';

import List from '@material-ui/core/List';
import ListItemButton from '@material-ui/core/ListItemButton';

import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import formatAddress from '../../utils/formatAddress';
import { Avatar, ListItemAvatar } from '@material-ui/core';

export const SelectStation = ({loading, location, stationSelected, stations, stationNotFound}) => {

    const [selectedStation, setSelectedStation] = useState(null);

    const returnStation = () => {
      if(selectedStation === 0) {
        stationNotFound(location);
      } else {
        stationSelected(selectedStation);
      }
      setSelectedStation(null);
    }

    const cancel = () => {
      stationSelected(null);
      setSelectedStation(null);
    }

    return(
        <React.Fragment>
            {location ?
            <Dialog onClose={()=>stationSelected(null)} open={!!location} onBackdropClick={()=>stationSelected(null)}>
            <DialogTitle>Pick the station from the list</DialogTitle>
            <Box sx={{padding: '0px 24px 0px 24px'}}>
              <Typography>Address: {location.description}</Typography>
            </Box>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {loading ?
              <div>Loading Stations...</div>
              :
              <List component="nav">
                {stations.map((station, index) => (
                  <React.Fragment key={index}>
                    <ListItemButton
                      selected={selectedStation && selectedStation.idstations === station.idstations}
                      onClick={() => setSelectedStation(station)}
                      divider
                      // sx={{
                      //   padding: 0,
                      //   "&.Mui-selected": {
                      //     backgroundColor: "red"
                      //   }
                      // }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={`Shell`}
                          src={`/images/Shell_logo.svg`}
                        />
                      </ListItemAvatar>
                    <ListItemText
                      primary={station.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {formatAddress(station)}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>

                </React.Fragment>
                ))}

                <ListItemButton
                    selected={selectedStation === 0}
                    onClick={() => setSelectedStation(0)}
                    >
                    <ListItemAvatar>
                      <Avatar
                        alt={`Station Not Found`}
                        src={`/images/search-not-found.svg`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={stations.length > 0 ? 'Station is not listed' : 'No Stations Found'}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Select this and will add the station manually for you
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
              </List>
              }
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', margin: '16px'}}>
              <Button
                variant="text"
                color="primary"
                onClick={cancel}
              >
                Cancel
              </Button>
              <Button
                disabled={selectedStation === null}
                variant="contained"
                color="primary"
                onClick={returnStation}
              >
                Select
              </Button>
            </Box>
          </Dialog>
            : undefined
            }
        </React.Fragment>
    )
}

export default SelectStation;