import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AppStateContext from '../../AppStateContext';

export default function YourDetails() {
  return (
    <AppStateContext.Consumer>
        {(appState) => (
          <React.Fragment>
            <Typography variant="h6" gutterBottom>
              Your Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField required id="dealername" label="Dealer/Wholesaler Name" fullWidth autoComplete="off"  value={appState.dealerName} onChange={(event)=>appState.setDealerName(event.target.value)}/>
              </Grid>
              <Grid item xs={12}>
                <TextField required id="fullname" label="Your Full Name" fullWidth autoComplete="name"  value={appState.name} onChange={(event)=>appState.setName(event.target.value)}/>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  label="Email Address"
                  // helperText="Your email address"
                  fullWidth
                  autoComplete="email"
                  value={appState.email}
                  onChange={(event)=>appState.setEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField required id="phone" label="Contact Phone Number" fullWidth autoComplete="tel"  value={appState.phone} onChange={(event)=>appState.setPhone(event.target.value)}/>
              </Grid>
            </Grid>
          </React.Fragment>
      )}
    </AppStateContext.Consumer>
  );
}