import React, {useContext} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import YourDetails from './YourDetails';
import AddStations from './AddStations';

import MobileProgress from '../MobileProgress/MobileProgress';
import Copyright from '../Copyright/Copyright';

import AppStateContext from '../../AppStateContext';
import Review from './Review';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Your Details', 'Add Stations', 'Review'];

function getStepContent(step) {
  switch (step) {
    case 1:
      return <YourDetails />;
    case 2:
      return <AddStations />;
    case 3:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}

export const Process = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const context = useContext(AppStateContext);

  const handleNext = async () => {
    if(activeStep !== steps.length) { // not the last step so go to next step
      setActiveStep(activeStep + 1);
    } else { // final step so submit
      await context.submitRegistration();
      history.push('/done');
    }

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <AppStateContext.Consumer>
      {(appState) => (
        <React.Fragment>
          <main className={classes.layout}>
            <Paper className={classes.paper} elevation={0}>
              {!mobileScreen && activeStep !== steps.length + 1 &&
                <Typography component="h1" variant="h4" align="center">
                  fuelService Registration
                </Typography>
              }
              {mobileScreen && activeStep !== steps.length + 1 &&
                <MobileProgress
                steps={steps}
                activeStep={activeStep}
                />
              }
              {!mobileScreen && activeStep !== steps.length + 1 &&
                <Stepper activeStep={activeStep - 1} className={classes.stepper}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              }
              
              <Box>
                {getStepContent(activeStep)}
                <Box className={classes.buttons}>
                  {activeStep !== 1 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    disabled={!appState.stepValid[activeStep]}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length ? 'Submit' : 'Next'}
                  </Button>
                </Box>
              </Box>

            </Paper>
            <Copyright />
          </main>
        </React.Fragment>
      )}
    </AppStateContext.Consumer>
  );
}

export default Process;