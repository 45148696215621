import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
  
export const MobileProgress = ({steps, activeStep}) => {
    const maxSteps = steps.length;
    return(
      <div>
          <Typography component="h3" variant="h5" align="center">
                {steps[activeStep - 1]} ({activeStep + '/' + maxSteps})
          </Typography>

          <MobileStepper
                sx={{ width: '100%', justifyContent: 'center' }}
                steps={maxSteps}
                position="static"
                variant="progress"
                activeStep={activeStep - 1}
              />

      </div>
    );
  }

export default MobileProgress;