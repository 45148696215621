import dljs from 'damerau-levenshtein-js';
import streetSuffix from 'street-suffix';

export const fuzzyAddressMatch = (input, db, allowedDifferences) => {
    input = normaliseString(input);
    db = normaliseString(db);

    // console.log('input = ' + input + ' db = ' + db + ' fuzzy distance ' + dljs.distance(input, db));

    return(dljs.distance(input, db) <= allowedDifferences)

}

const normaliseString = (data) => {
    data = data.toUpperCase();
    data = removePunctuation(data);

    // replace direction abbreviations
    data = data.replace(/(^| )N /g,' NORTH');
    data = data.replace(/(^| )S /g,' SOUTH');
    data = data.replace(/(^| )W /g,' WEST');
    data = data.replace(/(^| )E /g,' EAST');

    // remove unnecessary white space
    data = removeMoreThanOneSpace(data).trim();

    // replace all us postal abbreviations
    let dataSplit = data.split(' ');
    for(let i=0; i < dataSplit.length; i++) {
        const replacement = streetSuffix.expand(dataSplit[i])
        if(replacement) {
            dataSplit[i] = replacement;
        }
    }

    return(dataSplit.join(' '));
}

const removeMoreThanOneSpace = (input) => input.replace(/\s+/g, ' ');
const removePunctuation = (input) => input.replace(/[.,]/g, ' ');

export default fuzzyAddressMatch;